import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';

const FloatingActionButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 60,
        right: 16,
        zIndex: 1000,
        display: visible ? 'inline-flex' : 'none'
      }}
    >
      <Fab color="primary" onClick={scrollToTop}>
        <KeyboardArrowUpIcon />
      </Fab>
    </Box>
  );
};

export default FloatingActionButton;
