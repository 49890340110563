import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, List, ListItem, ListItemText, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import  { apiV1 } from '../services/api';

const CalendarManagementPage = () => {
  const [calendars, setCalendars] = useState([]);
  const [open, setOpen] = useState(false);
  const [newCalendarName, setNewCalendarName] = useState('');
  const [newCalendarYear, setNewCalendarYear] = useState(new Date().getFullYear());
  const navigate = useNavigate();

  useEffect(() => {
    fetchCalendars();
  }, []);

  const fetchCalendars = async () => {
    try {
      const response = await apiV1.get('/calendars');
      setCalendars(response.data);
    } catch (error) {
      console.error('Error fetching calendars:', error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewCalendarName('');
    setNewCalendarYear(new Date().getFullYear());
  };

  const handleCreateCalendar = async () => {
    try {
      await apiV1.post('/calendars', { name: newCalendarName, year: newCalendarYear });
      handleClose();
      fetchCalendars();
    } catch (error) {
      console.error('Error creating calendar:', error);
    }
  };

  const handleCalendarClick = (calendarId) => {
    navigate(`/calendar/${calendarId}`);
  };

  return (
    <Container sx={{paddingTop: 10}}>
      <Typography variant="h4" component="h1" gutterBottom>
        Your Calendars
      </Typography>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Create New Calendar
      </Button>
      <List>
        {calendars.map((calendar) => (
          <ListItem button key={calendar._id} onClick={() => handleCalendarClick(calendar._id)}>
            <ListItemText primary={calendar.name} secondary={`Year: ${calendar.year}`} />
          </ListItem>
        ))}
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Calendar</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Calendar Name"
            type="text"
            fullWidth
            value={newCalendarName}
            onChange={(e) => setNewCalendarName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Year"
            type="number"
            fullWidth
            value={newCalendarYear}
            onChange={(e) => setNewCalendarYear(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateCalendar} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CalendarManagementPage;