import React from 'react';
import { Typography, Box } from '@mui/material';

const AboutUsPage = () => {
  return (
    <Box sx={{ paddingTop: 10, paddingLeft: 2 }}>
      <Typography variant="h4">Products</Typography>
      <Typography variant="body1">This is the Products page.</Typography>
    </Box>
  );
};

export default AboutUsPage;
