import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { AuthProvider } from './contexts/AuthContext';
import LanguageProvider from './components/LanguageProvider';
import NavigationBar from './components/NavigationBar';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ProfilePage from './pages/ProfilePage';
import ProductsPage from './pages/ProductsPage';
import DocsPage from './pages/DocsPage';
import PricingPage from './pages/PricingPage';
import AboutUsPage from './pages/AboutUsPage';
import BlogPage from './pages/BlogPage';
import CalendarManagementPage from './pages/CalendarManagementPage';

const theme = createTheme();

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LanguageProvider>
         <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/*"
              element={
                <>
                  <NavigationBar />
                  <Routes>
                    <Route path="/" element={<Navigate to="/calendars" replace />} />
                    <Route path="/calendars" element={<PrivateRoute><CalendarManagementPage /></PrivateRoute>} />
                    <Route path="/calendar/:id" element={<PrivateRoute><HomePage /></PrivateRoute>} />
                    <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
                    <Route path="/products" element={<ProductsPage />} />
                    <Route path="/docs" element={<DocsPage />} />
                    <Route path="/pricing" element={<PricingPage />} />
                    <Route path="/about" element={<AboutUsPage />} />
                    <Route path="/blog" element={<BlogPage />} />
                  </Routes>
                </>
              }
            />
          </Routes>
        </Router>
        </LanguageProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};


export default App;