import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const ProfilePage = () => {
  const { user } = useAuth();

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Profile
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Typography>Username: {user.username}</Typography>
          <Typography>Email: {user.email}</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default ProfilePage;