import React from 'react';
import { Container, Paper, Box, Typography, Select, MenuItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AuthLayout = ({ children, title }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Select
          value={i18n.language}
          onChange={changeLanguage}
          sx={{ position: 'absolute', top: 20, right: 20 }}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="it">Italiano</MenuItem>
        </Select>
        <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            {t(title)}
          </Typography>
          {children}
        </Paper>
        <Box mt={3}>
          <Typography variant="body2" color="textSecondary" align="center">
            © {new Date().getFullYear()} {t('appName')}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            <RouterLink to="/" style={{ color: 'inherit' }}>
              {t('backToHome')}
            </RouterLink>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default AuthLayout;