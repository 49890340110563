import axios from 'axios';

const createApi = (version = 'v1') => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL  || `http://localhost:5001/api/${version}`,
    });

    api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                // Token non valido o scaduto
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                window.location = '/login';
            }
            return Promise.reject(error);
        }
    );

    return api;
};

export const apiV1 = createApi('v1');
// export const apiV2 = createApi('v2');