import React from 'react';
import YearView from '../components/YearView';
import FloatingActionButton from '../components/FloatingActionButton';

const HomePage = () => {
  return (
    <>
      <YearView />
      <FloatingActionButton />
    </>
  );
};

export default HomePage;
