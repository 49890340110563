import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Skeleton, Chip,Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { apiV1 } from '../services/api';
// import { useAuth } from '../contexts/AuthContext';

import AddIcon from '@mui/icons-material/Add';

const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 
  'July', 'August', 'September', 'October', 'November', 'December'
];

const daysInMonth = (month) => {
  return new Date(2024, month, 0).getDate();
};

const getDayOfWeek = (year, month, day) => {
  return new Date(year, month, day).getDay();
};

const MonthContainer = styled(Paper)(({ theme, compact }) => ({
  padding: compact ? theme.spacing(0.5) : theme.spacing(1),
  margin: compact ? theme.spacing(0.2) : theme.spacing(0.5),
  minWidth: '200px',
  textAlign: 'center',
}));

const MonthHeader = styled(Paper)(({ theme, compact }) => ({
  padding: compact ? theme.spacing(0.5) : theme.spacing(1),
  margin: compact ? theme.spacing(0.2) : theme.spacing(0.5),
  minWidth: '200px',
  textAlign: 'center',
  backgroundColor: 'white'
}));

const DayBox = styled(Box)(({ theme, isWeekend, isToday, compact }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: isToday ? theme.palette.success.light : isWeekend ? theme.palette.action.hover : 'white',
  padding: compact ? theme.spacing(0.2) : theme.spacing(0.5),
  margin: compact ? theme.spacing(0.10) : theme.spacing(0.25),
  minHeight: compact ? '30px' : '50px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative'
}));

const EventBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexGrow: 1
}));

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

const getEventsForDay = (day, events) => {
  return events.filter(event => {
    const start = new Date(event.startDate).setHours(0, 0, 0, 0);
    const end = new Date(event.endDate).setHours(23, 59, 59, 999);
    return day >= start && day <= end;
  });
};

const YearView = () => {
  const today = new Date();
 // const { user } = useAuth();
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);

  const [open, setOpen] = useState(false);
  const [eventText, setEventText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('normal');

  useEffect(() => {
    fetchCalendars();
  }, []);

  useEffect(() => {
    if (selectedCalendar) {
      fetchEvents(selectedCalendar);
    }
  }, [selectedCalendar]);

  const fetchCalendars = async () => {
    try {
      const response = await apiV1.get('/calendars');
      setCalendars(response.data);
      if (response.data.length > 0) {
        setSelectedCalendar(response.data[0]._id);
      }
    } catch (error) {
      console.error('Error fetching calendars:', error);
    }
  };

  const fetchEvents = async (calendarId) => {
    setLoading(true);
    try {
      const response = await apiV1.get(`/events/${calendarId}`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setSelectedEvent(null);
    setEventText('');
    setStartDate('');
    setEndDate('');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEventText('');
    setStartDate('');
    setEndDate('');
    setSelectedEvent(null);
  };

  const handleAddOrEditEvent = () => {
    if (eventText.trim()) {
      setEvents((prevEvents) => {
        const newEvents = [...prevEvents];
        if (selectedEvent !== null) {
          newEvents[selectedEvent] = { ...newEvents[selectedEvent], title: eventText, startDate, endDate };
        } else {
          newEvents.push({ id: newEvents.length + 1, title: eventText, startDate, endDate });
        }
        return newEvents;
      });
    }
    handleClose();
  };

  const handleEventClick = (event, eventIndex, eventText, eventStartDate, eventEndDate) => {
    event.stopPropagation(); // Prevent click from propagating to DayBox
    setSelectedEvent(eventIndex);
    setEventText(eventText);
    setStartDate(eventStartDate);
    setEndDate(eventEndDate);
    setOpen(true);
  };

  const handleViewChange = (event, newView) => {
    setView(newView);
  };

  const compact = view === 'compact';
  const maxEventLength = compact ? 15 : 20;

  return (
    <>
      <Box sx={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: 'white', zIndex: 1, display: 'flex', padding: 1,  flexGrow: 1 }}>
      <Typography variant="h5">{new Date().getFullYear()}</Typography>
      <Select
          value={selectedCalendar}
          onChange={(e) => setSelectedCalendar(e.target.value)}
          sx={{ marginLeft: 2, minWidth: 120 }}
        >
          {calendars.map((calendar) => (
            <MenuItem key={calendar._id} value={calendar._id}>{calendar.name}</MenuItem>
          ))}
        </Select>
      <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
          size='small'
        >
          Add Event
        </Button>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="view"
          size='small'
        >
          <ToggleButton value="normal" aria-label="normal view">
            Normal View
          </ToggleButton>
          <ToggleButton value="compact" aria-label="compact view">
            Compact View
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box>
      <Box sx={{ position: 'sticky', top: 54, backgroundColor: 'white', width: 'auto', zIndex: 2, display: 'flex', justifyContent: 'flex', padding: 1, flex: '1 1 auto' }}>
      <Box sx={{ display: 'flex' , backgroundColor: 'white'}}>
      {months.map((month, index) => (
        <MonthHeader key={index} compact={compact}  elevation={0}>
            <Typography variant="h6">{month}</Typography>
        </MonthHeader>
      
      ))}
      </Box>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: 8, paddingBottom: 8}}>
        {loading ? (
          <>
            {[...Array(8)].map((_, index) => (
              <Skeleton key={index} variant="rectangular" width={320} height={400} sx={{ margin: 2 }} />
            ))}
          </>
        ) : (
          months.map((month, index) => (
            <MonthContainer key={index} compact={compact}>
              <Grid container>
                {[...Array(daysInMonth(index + 1)).keys()].map(day => {
                  const date = new Date(2024, index, day + 1).setHours(0, 0, 0, 0);
                  const isWeekend = getDayOfWeek(2024, index, day + 1) % 6 === 0;
                  const isToday = date === today.setHours(0, 0, 0, 0);
                  const eventsForDay = getEventsForDay(date, events);
                  return (
                    <Grid item xs={12} key={day}>
                      <DayBox
                        isWeekend={isWeekend}
                        isToday={isToday}
                        compact={compact}
                      >
                        <Typography variant="body2" sx={{ width: '30px' }}>{day + 1}</Typography>
                        <EventBox>
                          {eventsForDay.map((event, idx) => (
                            <Tooltip key={event.id} title={event.title} arrow>
                              <Chip
                                label={truncateText(event.title, maxEventLength)}
                                onClick={(e) => handleEventClick(e, idx, event.title, event.startDate, event.endDate)}
                                size={compact ? 'small' : 'medium'}
                                sx={{ marginBottom: 0.5 }}
                              />
                            </Tooltip>
                          ))}
                        </EventBox>
                      </DayBox>
                    </Grid>
                  );
                })}
              </Grid>
            </MonthContainer>
          ))
        )}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{selectedEvent !== null ? 'Edit Event' : 'Add Event'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {selectedEvent !== null ? 'Edit your event below:' : 'Add a new event.'}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Event Title"
              type="text"
              fullWidth
              value={eventText}
              onChange={(e) => setEventText(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Start Date"
              type="date"
              fullWidth
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="dense"
              label="End Date"
              type="date"
              fullWidth
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAddOrEditEvent}>{selectedEvent !== null ? 'Save' : 'Add'}</Button>
          </DialogActions>
        </Dialog>
        </Box>
      </Box>
    </>
  );
};

export default YearView;
